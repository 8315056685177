import EnvelopeIcon from "../../../assets/icons/EnvelopeIcon";
import { PhoneIcon } from "../../../assets/icons/PhoneIcon";
import MappinIcon from "../../../assets/icons/MappinIcon";

const Address = () => {
  return (
    <>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center">
          <EnvelopeIcon className="h-6 w-6 text-primary" />
          <a
            href="mailto:info@adroitsystems.io"
            target="_blank"
            rel="noopener noreferrer"
          >
            info@adroitsystems.ae
          </a>
        </div>
        <div className="flex gap-2 items-center">
          <PhoneIcon className="h-6 w-6 text-primary" />
          <a target="_blank" rel="noreferrer" href="tel:+971 585 237648">
            +971 585 (ADROIT)
          </a>
        </div>
        <div className="flex gap-2 items-center">
          <MappinIcon className="h-6 w-6 text-primary" /> 6th Floor, Grandstand{" "}
          <br /> Meydan Free Zone <br /> Dubai, UAE.
        </div>
      </div>
      <div></div>
    </>
  );
};

export default Address;
